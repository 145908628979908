import SingleCarousal from "../Comp/SingleCaraousal";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Carousal() {
  const auth = useSelector((state) => state.user)
  const language = useSelector((state) => state.lang.language);

  const navigation = useNavigate()
  const routersAuth = () => {
    console.log(auth);
    
    if (!auth.isLogedIn) {
      navigation({ pathname: '/auth' })
    } else {
      navigation({pathname: '/'})
    }
  }
  React.useEffect(() => {
    routersAuth()
  }, [])
  return <div>
    <SingleCarousal />
    <div className="flex flex-col justify-center items-center pb-5 border-t-[0px] pt-2 -mx-0 bg-[#8DC73F] h-[19vh] ">
      {/* <IconsLoader icon="logosimple" width={50} height={50} /> */}
      <p className="text-[white] text-xs md:text-md text-center w-[100%] md:w-[100%] font-milkr">
        {language?.rules}
      </p>
    </div></div>
}
