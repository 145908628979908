import React from "react";
import { useSelector } from "react-redux";
import { IconsLoader } from "../../imports/icon";
import { Link } from 'react-router-dom';

export default function Description() {
  const language = useSelector((state) => state.lang.language);
  return (
    <div className="bottom-0">
      <div className="flex flex-col justify-center items-center pb-5 border-t-[1px] pt-5 mx-0 bg-[#8DC73F]">
        {/* <IconsLoader icon="logosimple" width={50} height={50} /> */}
        <p className="text-[white] text-sm md:text-md text-center w-[100%] md:w-[100%] font-milkl">
          {language?.rules}
        </p>
      </div>
      <div className="bg-[#242C37] py-5">
        <div className="flex flex-col justify-center items-center font-milkr text-xs">
          <a href="/contact-us/" className="text-[white] text-5xl  font-[800] py-2 font-milkb border-b-[0px] mb-2 text-center ">
            {language?.ethiotelebrandingname}
          </a>
          <div className="text-center text-white py-5 font-extralight">
            By entering this site you confirm that you accept our <Link
              href="/home/terms-and-service"
              className="text-[#8DC73F] font-[200] py-2 "
            >
              {language?.termandservice}
            </Link>    and have read our <Link
              href="/home/privacy"
              className="text-[#8DC73F] font-[200] py-2 "
            >
              {language?.privecyPolicy}
            </Link>
          </div>
          <div className="flex flex-row justify-between items-center w-[100%] px-[15%] md:px-[40vw] pb-2">
            <a href="https://www.facebook.com/kinetgames" target="_blank">
              <IconsLoader icon="facebook" width={30} height={30} />
            </a>
            <a href="https://www.instagram.com/kinetgames" target="_blank">
              <IconsLoader icon="instagram" width={30} height={30} />
            </a>
            <a
              href="https://www.tiktok.com/@kinet_games_official"
              target="_blank"
            >
              <IconsLoader icon="tiktok" width={30} height={30} />
            </a>
          </div>


        </div>
      </div>
    </div>
  );
}
