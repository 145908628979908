import { useState, useCallback } from "react";

const useSignInHook = () => {
    const [loading, setLoading] = useState(false);

    // Check if user is subscribed
    const checkIfSubscribed = async (phone) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL_FUNCTION}checkIfSubscribed`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ phone }),
                }
            );

            const data = await response.json();
            console.log("dat", data);
            
            return data.isSubscribed
        } catch (e) {
            return false
        }
    };


    const userAuthentication = async (phone) => {
        setLoading(true)
        let subscriptions = await checkIfSubscribed(phone)
        console.log("subscribed", subscriptions);
        
        // if user is not subscribed when he/she enters his/her phone will be returning a status
        if (!subscriptions) {
            setLoading(false)
            return { isSubscribed: false, user: null }
        }
        else {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL_FUNCTION}loginUserWithFirestore`,
                    {
                        method: "POST", // or 'PUT'
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ phone: phone }),
                    }
                );
                const res = await response.json();
                console.log("userdata is dfads", res)

                const updatedRes = {
                    ...res,
                    isSubscribed: subscriptions, // Example of overriding subscription data
                };
                
                setLoading(false)
                return updatedRes
            } catch (e) {
                setLoading(false);
                return { isSubscribed: false, user: null }
            }
        }
    }; // Dependency array


    return { userAuthentication, loading }
};

export default useSignInHook;
