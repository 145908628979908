import React, { useState, useEffect, useCallback } from "react";

import logo from "../../assets/images/logosimple.svg";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Drawer } from "antd";
import { useEnvironment } from "../../context/EnvironmentContext";
import { useSelector } from "react-redux";

import tele from "../../assets/images/tele.jpeg";
import { IconsLoader } from "../../imports/icon";

export default function Header() {
  const auth = useSelector((state) => state.user)
  const navigation = useNavigate()
  const [open, setOpen] = useState(false);
  const [webView, setWebView] = useState(false)
  const location = useLocation();
  const history = useNavigate();
  const pathname = location.pathname;
  const { isWebViewApp } = useEnvironment();

  

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const navigate = useCallback(
    (item) => {
      if (item.pathname !== pathname) {
        if (item.name === "signup") {
          console.log(item.name);
        } else {
          history(item.pathname);
        }
      }
    },
    [pathname]
  );

  const navItems = [
    {
      id: 1,
      name: "home-fill",
      inactive: "home-empity",
      pathname: "/",
    },
    {
      id: 2,
      name: "game-filed",
      inactive: "game-unfilled",
      pathname: "/games",
    },
    {
      id: 3,
      name: "profile-active",
      inactive: "profileunfilled",
      pathname: "/auth/",
    },
  ];

  const routersAuth = () => {
      if (!auth.isLoggedin){
          navigation({pathname: '/auth'})
      }
  }
  React.useEffect(() =>{
      routersAuth() 
  }, [])

  const NavItem = ({ item, pathname, navigate }) => (
    <a
      className={`w-[33.3%] flex justify-center transition-all ease-linear delay-190 duration-300 relative ${
        item.index < 2 ? "border-r-[1px]" : ""
      } py-3 xl:py-[2.5vh] cursor-pointer ${
        pathname === item.pathname ? "bg-[#8DC73F]" : "bg-white"
      }`}
      onClick={() => navigate(item)}
    >
      <IconsLoader
        priority
        width={100}
        height={80}
        className="w-[30px] h-[30px] xl:w-[10vw] xl:h-[3vh]"
        icon={pathname === item.pathname ? item.name : item.inactive}
      />
    </a>
  );


  return (
    <nav className={`bg-[#ffffff] ${false ? 'invisible h-[0px]' : 'visible'} w-[100%] md:px-[46px] px-[5px] flex flex-row justify-between items-center self-center py-0 shadow-lg top-0 sticky z-10 xl:h-[8vh]`}>
      <Link to="/">
        <img
          alt="logo"
          src={tele}
          className="w-[150px] md:w-[200px] h-[50px] md:h-[50px] xl:h-[8vh] xl:w-[15vw]"
        />
      </Link>
      <div className="invisible md:visible w-[30%] flex flex-row justify-between p-0">
        {navItems.map((item, index) => (
          <NavItem
            key={item.id}
            item={{ ...item, index }}
            pathname={pathname}
            navigate={navigate}
          />
        ))}
      </div>
      <Drawer
        placement="top"
        sha
        closable={false}
        height={"10vh"}
        className="!bg-transparent !mt-8"
        onClose={onClose}
        open={open}
        key={1}
      >
        <div className="w-[100%] flex flex-row justify-between p-0 h-20">
          {navItems.map((item, index) => (
            <NavItem
              key={item.id}
              item={{ ...item, index }}
              pathname={pathname}
              navigate={navigate}
            />
          ))}
        </div>
      </Drawer>
      {open ? null : (
        <div
          onClick={showDrawer}
          className="w-[50px] invisible sm:visible md:invisible fixed left-[45%] top-5"
        >
          <IconsLoader width={40} alt="more" icon={"more"} />
        </div>
      )}
      <div className="flex flex-row justify-between items-center">
        <Link to="/">
          <img
            priority="true"
            alt="logo"
            src={logo}
            width={100}
            height={80}
            className="w-[100%] md:w-[100%] h-[35px] md:h-[40px] xl:w-[10vw] xl:h-[7vh]"
          />
        </Link>
      </div>
    </nav>
  );
}
