import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from '../Components/Adons/Header'
import "react-phone-number-input/style.css";
import ReduxProvider from '../redux/store/ReduxProvider';
import Footer from '../Components/Adons/Footer'



const MainLayout = () => {


    React.useEffect(() => {
        const loadWasm = async () => {
          const response = await fetch(
            'https://firebasestorage.googleapis.com/v0/b/kinet-web.appspot.com/o/index.wasm?alt=media&token=b27a650e-ed73-4801-9dfe-ac205835b363'
          );
          const wasmBinary = await response.arrayBuffer();
    
          console.log('WASM loaded from cache or network:', wasmBinary);
        };
    
        loadWasm();
      }, []);
    return (
        <ReduxProvider>
            <section  className="min-h-[100vh] max-h-[100vh] h-auto w-[100%] bg-white">
                <Header />
                <Outlet />
                <Footer />
            </section>
        </ReduxProvider>
    );
};

export default MainLayout;
